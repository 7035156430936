import { __decorate, __extends } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import TabList from '@/components/TabList.vue';
import Pagination from '@/components/Pagination.vue';
import { aboutModule } from '@/store/modules/about';
var CreditList = (function (_super) {
    __extends(CreditList, _super);
    function CreditList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.page = 1;
        _this.rows = 6;
        return _this;
    }
    CreditList.prototype.mounted = function () {
        this.getCertificateList();
    };
    Object.defineProperty(CreditList.prototype, "certificateTitleList", {
        get: function () {
            return [
                {
                    id: 40,
                    name: this.$t('creditList.enterpriseHonor')
                },
                {
                    id: 41,
                    name: this.$t('creditList.systemCertificate')
                },
                {
                    id: 39,
                    name: this.$t('creditList.patentCertificate')
                },
                {
                    id: 38,
                    name: this.$t('creditList.copyright')
                },
                {
                    id: 42,
                    name: this.$t('creditList.productTesting')
                }
            ];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CreditList.prototype, "currentID", {
        get: function () {
            return aboutModule.certificateID;
        },
        set: function (val) {
            aboutModule.setCertificateID(val);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CreditList.prototype, "certificateList", {
        get: function () {
            return aboutModule.certificateList;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CreditList.prototype, "total", {
        get: function () {
            return aboutModule.certificateTotal;
        },
        enumerable: false,
        configurable: true
    });
    CreditList.prototype.setPage = function () {
        this.page = 1;
    };
    CreditList.prototype.pageWatch = function () {
        this.getCertificateList();
    };
    CreditList.prototype.getCertificateList = function () {
        aboutModule.getCertificateList({
            page: this.page,
            rows: this.rows,
            id: this.currentID
        });
    };
    __decorate([
        Watch('$data.page')
    ], CreditList.prototype, "pageWatch", null);
    CreditList = __decorate([
        Component({
            components: { TabList: TabList, Pagination: Pagination }
        })
    ], CreditList);
    return CreditList;
}(Vue));
export default CreditList;
